import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useLocation } from 'react-router-dom';
import Video from 'twilio-video';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import MeetingCompletedScreen from '../MeetingCompletedScreen/MeetingCompletedScreen';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
  finished,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const params = useParams() as any;
  const [step, setStep] = useState(Steps.roomNameStep);
  const queryParams = new URLSearchParams(useLocation().search);

  const usertype = queryParams.get('usertype');
  const token = queryParams.get('token');
  const userId = queryParams.get('userId') || '0';

  const URLRoomName = params.URLRoomName;

  const [name, setName] = useState<string>('');
  const [isError, setIsError] = useState(false);
  const [roomName, setRoomName] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_MEETING_ROOM_ENDPOINT}/${URLRoomName}/details?userType=${usertype}&token=${token}&userId=${userId}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }
    )
      .then(response => response.json())
      .then(data => ({ name: data.userName, title: data.title }))
      .then(data => {
        setName(data.name);
        setTitle(data.title);
      })
      .catch(error => {
        setIsError(true);
      });
  }, [URLRoomName]);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (name !== undefined && name.length > 0) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [name, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));

    setStep(Steps.deviceSelectionStep);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.roomNameStep && !isError && <LoadingScreen></LoadingScreen>}

      {step === Steps.roomNameStep && isError && <ErrorScreen meetingRoomName={URLRoomName} />}

      {step === Steps.finished && <MeetingCompletedScreen />}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} title={title} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
