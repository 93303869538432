import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#1d4164',
      height: '100%',
    },
    child: {
      flex: 1,
    },
  })
);

export default function MeetingCompletedScreen() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.child)}>
        <h3>Meeting Completed</h3>
        <div>Your meeting has finished. You may close this window to return to OPTIS now.</div>
      </div>
    </div>
  );
}
