import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#1d4164',
      height: '100%',
    },
    loadingIndicator: {
      flex: 1,
    },
  })
);

export default function LoadingScreen() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.loadingIndicator)}>
        <h3>Loading Meeting Details...</h3>
        <FontAwesomeIcon icon={faCircleNotch} size="6x" spin />
      </div>
    </div>
  );
}
